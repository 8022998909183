<template>
  <div id="contenido-pagina" class="container">
    <div class="alert alert-danger text-center">
      <div class="font-weight-bold">Acceso denegado</div>
      <div>Has accedido a una zona con acceso restringido</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccesoDenegado",
};
</script>
